<template>
    <div class="goals-grid">
        <template v-for="goal in goals" :key="goal.uid">
            <div class="goal-box">
                <div class="goal-title">{{goal.title}}</div>
                <div class="goal-end">
                    <div class="goal-type" :style="get_goal_color(goal.type)">{{goal.type}}</div>
                    <i class="material-icons" style="margin-left: 10px;">pending_actions</i>
                    <p>בתוקף עד: {{goal.date_end_js.toDate().toLocaleDateString('he')}}</p>
                </div>
                <div class="product-gallery">
                    <template v-for="item in goal.products" :key="item.makat">
                        <div class="item-image">
                            <div class="item-makat">
                                {{item.makat}}
                            </div>
                            <img :src="item.image" alt="">
                        </div>
                    </template>
                </div>
                <div class="agent-goals">
                    <table id="table">
                        <tr>
                            <th>מס</th>
                            <th>סוכן</th>
                            <th>ביצוע</th>
                            <th>יעד</th>
                            <th>הפרש</th>
                        </tr>
                            <template v-for="agent in goal.agents" :key="agent.id">
                                <tr v-if="agent.goal > 0">
                                    <td>{{agent.id}}</td>
                                    <td>{{agent.name}}</td>
                                    <td v-if="goal.type == 'כמותי'">{{(agent.amount / Number(goal.products[0].boxSize)).toFixed(0)}}</td>
                                    <td v-if="goal.type == 'פיזור'">{{agent.amount}}</td>
                                    <td v-if="goal.type == 'כספי'">{{agent.amount.toFixed(2)}}</td>
                                    <td>{{agent.goal}}</td>
                                    <td  v-if="goal.type == 'כמותי'" :class="check_margin(goal.type,agent.amount, agent.goal, goal.products[0].boxSize)">
                                        <p style="direction:ltr;">{{((agent.amount / Number(goal.products[0].boxSize)) - agent.goal).toFixed(0)}}</p>
                                    </td>
                                    <td v-if="goal.type == 'פיזור'" :class="check_margin(goal.type,agent.amount, agent.goal)">
                                        <p style="direction:ltr;">{{agent.amount - agent.goal}}</p>
                                    </td>
                                    <td v-if="goal.type == 'כספי'" :class="check_margin(goal.type,agent.amount, agent.goal)">
                                        <p style="direction:ltr;">{{(agent.amount - agent.goal).toFixed(2)}}</p>
                                    </td>
                                </tr>
                            </template>
                            <tr class="summary-row" style="background:#04AA6D;">
                                <td></td>
                                <td>סיכום</td>
                                <td>{{get_goal_meta(goal)[0].toFixed(0)}}</td>
                                <td>{{get_goal_meta(goal)[1]}}</td>
                                <td :style="check_diff(get_goal_meta(goal)[2])">{{(get_goal_meta(goal)[2]).toFixed(0)}}</td>
                            </tr>
                    </table>
                </div>
                <div class="last-update">
                    <p>עדכון אחרון</p>
                    <p v-if="goal.fetched">{{goal.fetched.toDate().toLocaleString('he')}}</p>
                    <p v-else> טרם עודכן</p>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { FireStore, projectFirestore } from '../../../firebase/config'
export default {
setup(){
    const timestamp = FireStore.Timestamp.fromDate(new Date(Date.now()))
    const goals = ref([])

    const get_goal_meta = (goal) => {
        let total = 0;
        let total_goal = 0;
        if(goal.agents){
            goal.agents.forEach(agent => {
                total += Number(agent.amount)
                total_goal += Number(agent.goal)
            })
        }
        if(goal.type == 'כמותי'){
            total = total / Number(goal.products[0].boxSize)
        }
        let diff = total_goal - total
        return [total, total_goal, diff]
    }
    const check_diff = (diff) => {
        if(diff < 0){
            return "color: #fff; direction:ltr"
        }else{
            return 'color: var(--danger); direction:ltr'
        }
    }
    const get_goal_color = (type) => {
        if(type == 'כמותי'){
            return 'background:var(--danger);'
        }
    }

    const get_goals = async () => {
        const docs = await projectFirestore.collection('Goals').where('date_end_js', '>', timestamp).get()
        if(!docs.empty){
            goals.value = docs.docs.map(doc => doc.data());
        }
    }
    if(goals.value.length == 0){
        get_goals();
    }
    const check_margin = (type, amount, agent_goal, boxSize) => {
        if(type == 'כמותי'){
            let res = (amount / Number(boxSize)) - agent_goal
            if(res > 0){
                return 'positive-margin'
            }else{
                return 'negative-margin'
            }
        }
        if(type == 'פיזור' || type == 'כספי'){
            let res = amount - agent_goal
            if(res > 0){
                return 'positive-margin'
            }else{
                return 'negative-margin'
            }
        }
    }    
    return{
        goals, check_margin, get_goal_color,get_goal_meta,check_diff
    }
}
}
</script>

<style scoped>
.goals-grid{
    width: 100%;
    height: fit-content;
    display: grid;
    grid-template-columns: calc(50% - 5px) calc(50% - 5px);
    grid-auto-rows: 650px;
    flex-shrink: 0;
    gap: 5px;
}
.goal-box{
    position: relative;
    width: 100%;
    height: 100%;
    background: var(--secondary);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
.goal-title{
    width: 100%;
    background: var(--purple);
    color: white;
    text-shadow: 0 0 3px black;
    text-align: center;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
}
.goal-end{
    position: relative;
    width: 100%;
    background: var(--alert-purple);
    color: white;
    text-shadow: 0 0 3px black;
    text-align: center;
    height: 35px;
    display: flex;
    justify-content: start;
    align-items: center;
    font-size: 18px;
    padding: 0 5px 0 0;
}
.product-gallery{
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    overflow-x: auto;
    padding: 3px;
    background: var(--alert-purple);
}
.item-image{
    height: 100%;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
    position: relative;
    overflow: hidden;
}
.item-makat{
    width: 100%;
    position: absolute;
    bottom: 0;
    background: var(--secondary);
    overflow: hidden;
    text-align: center;
    color: white;
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.349);
}

.item-image img{
    max-width: 100%;
    max-height: 100%;
}
.goal-type{
    position: absolute;
    top: 5px;
    left: 5px;
    width: fit-content;
    padding: 0 5px 0 5px;
    background: var(--success);
    color: white;
    text-shadow: 0 0 3px black;
    text-align: center;
    border-radius: 3px;
}
.agent-goals{
    width: 100%;
    height: calc(100% - 170px - 50px);
    overflow-y: auto;
}
.goal-summery{
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    padding: 5px;
}
#table {
    position: relative;
    border-collapse: collapse;
    width: 100%;
}
#table td, #table th {
  border: 1px solid #ddd;
  padding: 8px;
}
#table tr:nth-child(even){background-color: #f2f2f2;}
#table tr:nth-child(odd){background-color: #e7e6e6;}

#table tr:hover {background-color: #ddd;}

#table td:last-child{
    text-align: center;
    color: var(--secondary);
}
#table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #04AA6D;
  color: white;
  position: sticky;
  top: 0;
}
.summary-row{
    color:#fff;
    font-size:18px;
    position: sticky;
    bottom: 0;
    left: 0;
}
.goal-btn{
    margin: 0;
    margin-left: 5px;
}
.last-update{
    color: white;
    padding-right: 10px;
}
.positive-margin{
    color: green;
}
.positive-margin p{
    color: green;
}
.negative-margin{
    color: red;
}
.negative-margin p{
    color: red;
}
@media only screen and (max-width: 600px) {
    .goals-grid{
        grid-template-columns: 100%;
        grid-auto-rows: 650px;
    }
}
</style>