<template>
    <div class="agent-portal">
        <div class="header">
            <p>פורטל סוכנים</p>
        </div>
        <div class="btns">
            <div class="btn" @click="go_to('/agentalbum')">
                <div class="btn-icon">
                    <i class="material-icons" style="font-size:30px;"> collections</i>
                </div>
                <div class="btn-desc">
                    <p>פתח אלבום</p>
                </div>
            </div>
            <div class="btn" @click="go_to('/routetoday')">
                <div class="btn-icon">
                    <i class="material-icons" style="font-size:30px;"> alt_route</i>
                </div>
                <div class="btn-desc">
                    <p>מסלול היום</p>
                </div>
            </div>
            <div class="btn">
                <div class="btn-icon">
                    <i class="material-icons" style="font-size:30px;"> assignment_returned</i>
                </div>
                <div class="btn-desc">
                    <p>בצע החזרה</p>
                </div>
            </div>
            <div class="btn" @click="go_to('/update-credit-payment')">
                <div class="btn-icon">
                    <i class="material-icons" style="font-size:30px;">paid</i>
                </div>
                <div class="btn-desc">
                    <p>עדכון ת.אשראי</p>
                </div>
            </div>
            <div class="btn" @click="go_to('/new-client')">
                <div class="btn-icon" >
                    <i class="material-icons" style="font-size:30px;"> person_add</i>
                </div>
                <div class="btn-desc">
                    <p>לקוח חדש</p>
                </div>
            </div>
            <div class="btn">
                <div class="btn-icon">
                    <i class="material-icons" style="font-size:30px;"> camera</i>
                </div>
                <div class="btn-desc">
                    <p>תצוגות ובמות</p>
                </div>
            </div>
            <div class="btn">
                <div class="btn-icon">
                    <i class="material-icons" style="font-size:30px;"> local_shipping</i>
                </div>
                <div class="btn-desc">
                    <p>הזמנות מהיום</p>
                </div>
            </div>
            <div class="btn" @click="go_to('/agents_documents')">
                <div class="btn-icon">
                    <i class="material-icons" style="font-size:30px;"> perm_media</i>
                </div>
                <div class="btn-desc">
                    <p>מסמכי סוכנים</p>
                </div>
            </div>
            <div class="btn admin" @click="go_to('/agentlocations')" v-if="user_role > 99">
                <div class="btn-icon">
                    <i class="material-icons" style="font-size:30px;"> share_location</i>
                </div>
                <div class="btn-desc">
                    <p>סוכנים ולקוחות</p>
                </div>
            </div>
            <div class="btn admin" v-if="user_role > 99">
                <div class="btn-icon">
                    <i class="material-icons" style="font-size:30px;"> point_of_sale</i>
                </div>
                <div class="btn-desc">
                    <p>בקשות מכירות</p>
                </div>
            </div>
            <div  @click="go_to('/lidim')" class="btn">
                <div class="btn-icon">
                    <i class="material-icons" style="font-size:30px;"> point_of_sale</i>
                </div>
                <div class="btn-desc">
                    <p>לידים לטיפול</p>
                </div>
            </div>
             <div @click="go_to('/gvia')" class="btn">
                <div class="btn-icon">
                    <i class="material-icons" style="font-size:30px;"> credit_card</i>
                </div>
                <div class="btn-desc">
                    <p>גבייה</p>
                </div>
            </div>
        </div>
        <div class="goals">
            <GoalsMini/>
        </div>
            
        
    </div>
</template>

<script>
import router from '../router'
import GoalsMini from '../components/Admin/Goals/GoalsSummMini.vue';
import { ref } from '@vue/reactivity';
import { computed } from '@vue/runtime-core';
import store from '../store';
export default {
components:{GoalsMini},
setup(){
    const go_to = (path) => {
        router.push(path)
    }
    
    const user_role = ref(computed(() => {
        return store.state.role
    }))

    const user = ref(computed(() => {
        console.log(store.getters.user);
        return store.getters.user
    }))
    
    return{
        go_to,user, user_role
    }
}
}
</script>

<style scoped>
.agent-portal{
    width: 100%;
    height: 100%;
    max-width: 1024px;
    margin: auto;
}
.header{
    width: 100%;
    height: 100px;
    background: var(--alert-purple);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 36px;
    color: white;
    text-shadow: 0 0 8px rgba(0, 0, 0, 0.699);
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}
.btns{
    width: 100%;
    height:fit-content;
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(200px, calc(100% / 3.1)) );
    grid-auto-rows: 100px;
    gap: 10px;
    padding: 5px 5px 0 5px;
    overflow-y: auto;
    justify-content: space-between;
}
.btn{
    user-select: none;
    background:linear-gradient(125deg, var(--purple), rgb(119, 7, 224));
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.05);
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.699);
}
.btn-icon{
    width: 50px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: whitesmoke;
}
.btn-desc{
    width: fit-content;
    height: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    color: whitesmoke;
    font-size: 24px;
}
.admin {
    background:linear-gradient(125deg, var(--success), rgb(24, 158, 53));
}
.goals{
    width: 100%;
    height: fit-content;
    overflow-y: auto;
}
.title{
    width: 100%;
    text-align: center;
    font-size: 24px;
    height: fit-content;
    padding: 5px 0 5px 0;
    background: var(--success);
    color: whitesmoke;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.699);
}

@media only screen and (max-width: 600px) {
    .btns{
        width: 100%;
        height: calc(50% - 50px);
        display: grid;
        grid-template-columns: repeat( auto-fit, minmax(40%, calc(100% / 2.1)) );
        grid-auto-rows: 80px;
        gap: 10px;
        padding: 10px;
        overflow-y: auto;
        justify-content: center;
    }
    .btn-desc{
        font-size: 18px;
    }
}
</style>